import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import type { VueClass } from "vue-class-component/lib/declarations";
import {
  MigrationMonitor,
  MigrationMonitorCollection,
} from "@planetadeleste/vue-mc-gw";
import { delay } from "lodash";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<MigrationMonitor, MigrationMonitorCollection>
>;

@Component
export default class MigrationsMixin extends Mixins(TypedModelMixin) {
  obCollection = new MigrationMonitorCollection();
  obModelClass = MigrationMonitor;
  sRoutePath = "/migrations";

  created() {
    this.onCreated();
  }

  onRegisterEvents() {
    this.addEvent("after.hide.form", () => {
      delay(() => {
        this.emit("reload.index");
      }, 1500);
    });
  }
}
