<template>
  <v-row>
    <v-col cols="12">
      <form-field-text v-model="obMigrationMonitor.rut" label="rut" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { MigrationMonitor } from "@planetadeleste/vue-mc-gw";

@Component
export default class MigrationsSettings extends Vue {
  @VModel({ type: Object, default: () => new MigrationMonitor() })
  obMigrationMonitor!: MigrationMonitor;
}
</script>
