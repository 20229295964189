<template>
  <v-dialog v-model="drawer" max-width="800" persistent scrollable>
    <v-form v-if="obModel" class="fill-height" @submit.prevent>
      <gw-form-observer
        :loading="isLoading"
        hide-top-actions
        @cancel="cancel"
        @save="save"
      >
        <settings v-model="obModel" @save="save" />
      </gw-form-observer>
    </v-form>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Mixins, PropSync } from "vue-property-decorator";
import MigrationsMixin from "@/modules/migrations/mixins/MigrationsMixin";
import Settings from "@/modules/migrations/components/tabs/Settings.vue";
import type { ResultData } from "vue-mc";
import type { MigrationMonitorData } from "@planetadeleste/vue-mc-gw";

@Component({
  components: {
    Settings,
  },
})
export default class MigrationsForm extends Mixins(MigrationsMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;

  async saved(response: ResultData<MigrationMonitorData>) {
    if (!response.data.id) {
      return;
    }

    await this.$router.push({
      name: "migrations.view",
      params: { id: response.data.id as unknown as string },
    });
  }
}
</script>
